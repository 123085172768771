import React from "react";
import { Link } from "gatsby";
import SocialMediaButtons from "../components/SocialMediaButtons";

const CallToAction = ({ to }) => {
  return (
    <div className="w-full">
      <div className="flex justify-center">
        <Link
          to={to}
          className="text-center w-1/2 mx-2 bg-yellow-400 cursor-pointer hover:bg-gray-900 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-yellow-500 hover:border-transparent rounded"
          activeClassName="text-center w-1/2 mx-2 bg-yellow-400 cursor-pointer hover:bg-gray-900 text-gray-700 font-semibold hover:text-white py-2 px-4 border border-yellow-500 hover:border-transparent rounded"
        >
          Leia aqui!
        </Link>
        <SocialMediaButtons />
      </div>
    </div>
  );
};

export default CallToAction;
