import React from "react";
import ArticleCard from "../components/ArticleCard";
import ButtonOutlined from "../components/ButtonOutlined";
import Layout from "../components/Layout";
import SideBar from "../components/SideBar";
import SEO from "../components/SEO";
import { graphql } from "gatsby";

export const PaginationQuery = graphql`
  query($skip: Int!, $limit: Int!, $slugRegex: String!) {
    allContentfulArticle(
      sort: { fields: publishedDate, order: DESC }
      skip: $skip
      limit: $limit
      filter: { categories: { elemMatch: { slug: { regex: $slugRegex } } } }
    ) {
      totalCount
      edges {
        node {
          categories {
            title
            slug
          }
          articleCover {
            title
            fluid(maxWidth: 1028) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          title
          description
          slug
          publishedDate(formatString: "MMMM, YYYY", locale: "pt-BR")
        }
      }
    }
  }
`;

const Pagination = (props) => {
  return (
    <Layout>
      <SEO title={props.pageContext.pageTitle} />
      <div className="flex flex-col lg:flex-row">
        <div className="w-full lg:w-8/12 relative text-white">
          {props.data.allContentfulArticle.edges.map((edge, i) => {
            return (
              <ArticleCard
                key={i}
                title={edge.node.title}
                description={edge.node.description}
                publishedDate={edge.node.publishedDate}
                categories={edge.node.categories}
                alt={edge.node.articleCover.title}
                slug={edge.node.slug}
                fluid={edge.node.articleCover.fluid}
              />
            );
          })}
          <div className="mt-2 flex justify-between">
            {props.pageContext.pageNumber + 1 <=
            props.pageContext.totalPages ? (
              <ButtonOutlined
                text="PRÓXIMA PÁGINA"
                to={
                  (props.pageContext.slugRegex || "/") +
                  (props.pageContext.pageNumber + 1)
                }
              />
            ) : null}
            {props.pageContext.pageNumber - 1 === 0 ? (
              <ButtonOutlined text="VOLTAR" to="/" />
            ) : (
              <ButtonOutlined
                text="PÁGINA ANTERIOR"
                to={
                  (props.pageContext.slugRegex || "/") +
                  (props.pageContext.pageNumber - 1)
                }
              />
            )}
          </div>
        </div>
        <div className="w-full lg:w-4/12 mt-2 lg:pl-2 text-white">
          <SideBar />
        </div>
      </div>
    </Layout>
  );
};

export default Pagination;
