import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const SocialMediaButtons = () => {
  const smbQuery = useStaticQuery(graphql`
    query {
      allFile(filter: { relativeDirectory: { eq: "socialmedia" } }) {
        edges {
          node {
            name
            childImageSharp {
              fixed(width: 24, height: 24) {
                ...GatsbyImageSharpFixed_withWebp_tracedSVG
              }
            }
          }
        }
      }
    }
  `);

  const twitterNode = smbQuery.allFile.edges.filter(
    (edge) => edge.node.name === "twitter"
  )[0].node;
  const facebookNode = smbQuery.allFile.edges.filter(
    (edge) => edge.node.name === "facebook"
  )[0].node;
  const pinterest = smbQuery.allFile.edges.filter(
    (edge) => edge.node.name === "pinterest"
  )[0].node;
  return (
    <div className="flex flex-row">
      <button className="flex items-center h-full ml-2 focus:outline-none">
        <Img
          alt={twitterNode.name}
          title={twitterNode.name}
          fixed={twitterNode.childImageSharp.fixed}
        />
      </button>
      <button className="flex items-center h-full ml-4 focus:outline-none">
        <Img
          alt={facebookNode.name}
          title={facebookNode.name}
          fixed={facebookNode.childImageSharp.fixed}
        />
      </button>
      <button className="flex items-center h-full ml-4 focus:outline-none">
        <Img
          alt={pinterest.name}
          title={pinterest.name}
          fixed={pinterest.childImageSharp.fixed}
        />
      </button>
    </div>
  );
};

export default SocialMediaButtons;
