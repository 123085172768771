import React from "react";
import { Link } from "gatsby";

const compareStrings = (a, b) => {
  // Assuming you want case-insensitive comparison
  // console.log(a, b);
  a = a.toLowerCase();
  b = b.toLowerCase();

  return a < b ? -1 : a > b ? 1 : 0;
};

const CategoriesRibbon = ({ categories }) => {
  return (
    <React.Fragment>
      {categories
        .sort((a, b) => compareStrings(a.title, b.title))
        .map((category, i) => {
          let linkClass = "";
          if (i === 0) {
            linkClass =
              "text-xs px-2 bg-transparent cursor-pointer hover:bg-yellow-400 text-yellow-400 font-semibold hover:text-white border border-yellow-400 hover:border-transparent rounded";
          } else {
            linkClass =
              "text-xs px-2 ml-2 bg-transparent cursor-pointer hover:bg-yellow-400 text-yellow-400 font-semibold hover:text-white border border-yellow-400 hover:border-transparent rounded";
          }
          return (
            <Link
              key={i}
              to={"/" + category.slug + "/1"}
              className={linkClass}
              activeClassName={linkClass}
            >
              {category.title}
            </Link>
          );
        })}
    </React.Fragment>
  );
};
export default CategoriesRibbon;
