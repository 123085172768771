import React from "react";
import CallToAction from "../components/CallToAction";
import { Link } from "gatsby";
import CategoriesRibbon from "../components/CategoriesRibbon";
import Img from "gatsby-image";
const ArticleCard = ({
  title,
  description,
  publishedDate,
  categories,
  alt,
  slug,
  fluid,
}) => {
  return (
    <React.Fragment>
      <div className="md:flex bg-gray-700 rounded-md h-60 mt-2 relative">
        <Link to={"/" + slug} className="md:w-1/2" activeClassName="md:w-1/2">
          {/* <img
            src={articleCoverSrc}
            className="h-60 w-full object-cover object-center md:rounded-r-none rounded-md relative top-0 left-0 hover:shadow-outline"
            alt={articleCoverAlt}
          /> */}
          {/* {JSON.stringify(fluidProps, null, 4)} */}

          <Img
            fluid={fluid}
            alt={alt}
            title={alt}
            className="h-60 object-cover object-center md:rounded-r-none rounded-md relative top-0 left-0 hover:shadow-outline"
          />
        </Link>
        <div className="h-60 w-full md:invisible absolute top-0 bg-gray-600 opacity-50 rounded-lg" />
        <div className="md:w-1/2 w-full md:relative absolute top-0">
          <div className="flex flex-col justify-center h-60 px-4">
            <Link to={"/" + slug}>
              <h2 className="text-lg font-semibold text-center text-white hover:underline ">
                {title}
              </h2>
            </Link>
            <time className="text-xs text-gray-400 capitalize">
              {publishedDate}
            </time>
            <h3 className="text-base font-base text-white text-justify">
              {description}
            </h3>
            <div className="flex mb-2">
              <CategoriesRibbon categories={categories} />
            </div>
            {/* call to action com social media */}
            <CallToAction to={"/" + slug} />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ArticleCard;
